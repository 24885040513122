import React, { useState } from "react"
import styled from "styled-components";
import { theme } from "../../utils";

const Button = styled.button`
    background: none;
    border: none;
    font-size: 2rem;
    margin: 0 12px;
    transition: transform .3s ease-in-out;
    color: silver;

    &:hover:not(.voted), &.selected {
        transform: scale(1.2);
        color: ${theme.colors.primary};
    }
`

const QuickFeedback = ({category=''}) => {

    const dataLayer = window.dataLayer || [];

    const [isHappy, setIsHappy] = useState(false);
    const [isSad, setIsSad] = useState(false);
    const [voted, setVoted] = useState(false);
    
    let event = {
        event: 'quick-feedback',
        feedback: '',
        category: category
    }

    const handleHappy = () => {
        if (!voted) {
            setIsHappy(true)
            setIsSad(false)
            event.feedback = 'positive'
            dataLayer.push(event)
            setVoted(true)
        }
    } 

    const handleSad = () => {
        if (!voted) {
            setIsHappy(false)
            setIsSad(true)
            event.feedback = 'negative'
            dataLayer.push(event)
            setVoted(true)
        }
    }
    
    return (
        <div>
            <div className="d-flex justify-content-center">
                <Button 
                    className={`${isHappy ? 'selected' : ''} ${voted ? 'voted' : ''}`} 
                    onClick={handleHappy}
                    disabled={voted}
                >
                    <i className="far fa-smile"></i>
                </Button>
                <Button 
                    className={`${isSad ? 'selected' : ''} ${voted ? 'voted' : ''}`} 
                    onClick={handleSad}
                    disabled={voted}
                >
                    <i className="far fa-frown"></i>
                </Button>
            </div>
            <div className={`msg msg-happy ${isHappy ? '' : 'd-none'}`}>
                <span>Thank you for your feedback!</span>
            </div>
            <div className={`msg msg-sad ${isSad ? '' : 'd-none'}`}>
                <span>Sorry to hear that, please <a href="mailto: people@cofraholding.com?subject=Question about COFRA Careers" target="_blank">contact us</a>.</span>
            </div>
        </div>
    )
  }

export default QuickFeedback