import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import SEO from "../../../components/seo"
import PageWrapper from "../../../components/PageWrapper"
import { Container, Col, Row } from "react-bootstrap"
import { useContent } from "../../../hooks/useContent"
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect"
import PageTitle from "../../../components/Core/PageTitle"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import useBreakpoint from "../../../hooks/useBreakpoint"
import QuickFeedback from "../../../components/QuickFeedback"
import SectionSeparator from "../../../components/Core/SectionSeparator"
import Sticky from "react-stickynode"


const Content = styled.div`

    & h2 {
        line-height: 1 !important;
    }

`

const headerConfig = {
    paddingBottom: 10,
    variant: 'minimal'
};

const InfoMentorship = () => {
    
    const content = useContent()

    return (
        <>
            <UnauthenticatedTemplate>
                <NoAuthRedirect />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title="Mentorship@COFRA" />
                    <Content className="">
                        <Container>
                            <Breadcrumb className="mt-20">
                                <Breadcrumb.Item active><Link to="/">Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item href="/what-you-need-to-know">
                                    <Link to="/what-you-need-to-know">{content.get('what-you-need-to-know-page-title', true)}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {content.get('what-you-need-to-know-mentorship-page-title', true)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Container>
                        <PageTitle 
                            align="left"
                            title={ content.get('what-you-need-to-know-mentorship-page-title', true) }
                        />
                        <div class="inner-content">
                            <Container>
                                <Row className="">
                                    <Col className="order-1 order-lg-0 col-12 col-lg-8 col-xl-6">
                                        <h1 id="contents">Contents</h1>
                                        {content.getTOC('what-you-need-to-know-mentorship-content')}
                                        <SectionSeparator />
                                        {content.get('what-you-need-to-know-mentorship-content')}
                                    </Col>
                                    <Col className="order-0 order-lg-1 col-auto col-lg-4 col-xl-6 pb-10 justify-content-center text-center sidebar">
                                        <Sticky 
                                            enabled={['xsm','sm','md'].includes(useBreakpoint()) ? false : true}
                                            top={120}
                                            bottomBoundary=".sidebar"
                                        >
                                            <div className="mb-5">
                                                <Link
                                                    to="/mentorship/mentor"
                                                    className="btn btn-primary my-4 text-uppercase w-100 w-sm-auto"
                                                    size={['xsm','sm','md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                                                >
                                                    {content.get('mentorship-intro-button-sign-up-mentor', true)}
                                                </Link>
                                                <br />
                                                <Link
                                                    to="/mentorship/mentee"
                                                    className="btn btn-secondary text-uppercase w-100 w-sm-auto my-4"
                                                    size={['xsm','sm','md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                                                >
                                                    {content.get('mentorship-intro-button-sign-up-mentee', true)}
                                                </Link>
                                                <div className="mt-5 d-none d-lg-block">
                                                    <a href="#contents">Back to contents ↑</a>
                                                </div>
                                            </div>
                                        </Sticky>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <SectionSeparator fullWidth={true} />
                        <Container className="py-14">
                            <Row className="justify-content-center">
                                <h3 className="font-family-primary font-size-5 mb-8 text-center">Did you find the answer to your question?</h3>
                            </Row>
                            <Row className="justify-content-center">
                                <QuickFeedback category="found-answer-to-question" />
                            </Row>
                        </Container>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default InfoMentorship